const env = {};

env.config = {
  api: {
    url: 'https://extranet.occicom.fr/api/',
    AppName: "Web",
    AppPlatform: "Web",
    AppKey: "cda8aa56258edc7d18a9038e398772f1",
    AppVersion: "3.26.0"
 },
  notification: {
    url: 'https://notification.compagnon.production.k2project.fr/api/v1/'
  },
  chat: {
    url: 'https://chat.compagnon.production.k2project.fr/'
  },
  voicemail: {
    url: 'https://voicemail.compagnon.production.k2project.fr/api/v1/'
  },
  mail: {
    url: 'https://mail.compagnon.production.k2project.fr/api/v1/'
  },
  log: {
    url: 'https://log.compagnon.production.k2project.fr/api/v1/'
  },
  extranet: {
    url: 'https://occicomapi.staging.k2project.fr/'
  },
  firebase: {
    applicationName : 'occicom_compagnon'
  },
  themes: {
    light: {
      primary: '#df0e1d',
      secondary: '#b0bec5',
      accent: '#8c9eff',
      error: '#b71c1c',
      loading: '#000000',
      chatContent: '#e4e6eb',
      chatContentOwn: '#ffeb3b'
    },
    dark: {
      primary: '#ffeb3b',
      secondary: '#b0bec5',
      accent: '#8c9eff',
      error: '#b71c1c',
      loading: '#ffeb3b',
      chatContent: '#272727',
      chatContentOwn: '#272727'
    }
  },
  society: {
    name: "Occicom",
    supportUrl: "https://www.occicom.fr/contact/"
  },
  custom: {    
    appBarColor: {
      light: 'yellow',
      dark: '#121212'
    },
    appBarBtnColor: {
      light: 'black',
      dark: 'yellow'
    },
    societyBtnClass: 'occicom-btn'
  }
};

env.install = function (Vue) {
  // 4. ajouter une méthode d'instance
  Vue.prototype.$env = env.config;
}

module.exports = env;